import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import axios from 'axios'
import { Link } from 'react-router-dom'
import FGVLogo from '../assets/img/logo_fgv.svg'

const SubmitButton = styled.button`
    border-radius:0;
    height:40px;
    background-color:#003E7E;
    border:none;
    width:100%;
    box-shadow: 3px 3px 4px #ccc;
    color:#ddd;
    &:hover{
        background-color:#00366b;
    }
    &:active{
        box-shadow:none;
    }
    &:disabled{
        opacity:0.5;
        pointer-events:none;
    }
`
const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        borderRadius:0,
        border:'solid 1px #ddd',
        color:'#003E7E'
      }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
    menu: (provided) => ({
        ...provided,
        borderRadius:0,
    }),
    placeholder:(provided) => ({
        ...provided,
        color:'#003E7E',
        opacity:'.5',
    })
}

const Home = ({ className }) => {
    const [urls, setUrls] = React.useState([])
    const [pickedUrl, setPickedUrl] = React.useState(null)
    const [visibleContent, setVisibleContent] = React.useState(false)

    const fetchUrls = async () => {
        try {
            const urlsResponse = await axios.get(`${process.env.REACT_APP_API_HOST}/exam-urls`)

            let urlOptions = urlsResponse.data.map(x => ({ value: x.url, label: x.name }))
            setUrls(urlOptions)
        } catch (error) {
            console.error(error)
        }
    }
    React.useEffect(() => {
        fetchUrls()
        return () => setPickedUrl('')
    }, [])



    return (
        <main className={className}>

            <section className={"form-section" + visibleContent ?  "section-visible" : ""} >
                <img width="60%" src={FGVLogo} alt="logo-fgv"/>
                <h2>PLATAFORMA DE EXAMES</h2>
                <div className="select-container">
                    <p>Escolha um exame abaixo:</p>
                    <Select
                        styles={customStyles}
                        options={urls}
                        onChange={(selected) => setPickedUrl(selected.value)}
                        placeholder="Selecione..."
                        width="100%"
                    />
                    <a href={pickedUrl}>
                        <SubmitButton disabled={!pickedUrl} >
                            ENTRAR
                        </SubmitButton>
                    </a>
                </div>
            </section>

        </main>
    )
}

export default styled(Home)`

    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color:#ddd;
    width:100vw;
    height:100vh;
    display:flex;
    padding-top:5em;
    justify-content:center;
    color:#003E7E;
    .form-section {
        transition: opacity 500ms ease-in;
        opacity:0;
    }
    .section-visible {
        display:flex;
        gap:5%;
        flex-direction:column;
        opacity:1;
    }
    .select-container {
        display:flex;
        flex-direction:column;
        gap:30px;
        width:30vw;
        background-color:white;
        padding:50px 20px;
        box-shadow: 3px 3px 5px #aaa;
        h4: {
            text-align:center;
        }
    }
    h2 {
        text-align:center;
        font-size:12pt;
    }
    .section-invisible {
        visibility:hidden;
    }
    img {
        align-self:center;
    }
    @media screen and (max-width:550px) {
        .select-container {
            width:90vw;
        }
    }
    @media screen and (max-width:800px) {
        .select-container {
            width:80vw;
        }
    }
`
