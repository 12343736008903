import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './components/Home'
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`


function App({className}) {
  return <Router >
    <GlobalStyle/>
    <Switch>
      <Route exact path='/'>
        <Home/>
      </Route>
    </Switch>
  </Router>;
}

export default App;
